<template>
  <div class="modal" @click.stop :class="{ noPadding }">
    <div
      class="popin"
      :class="[isOxilioModal ? 'oxilio-modal' : 'special-class', className]"
    >
      <CloseIcon class="close" @click="close" />
      <slot />
      <div class="buttons" v-if="buttons">
        <div
          class="button"
          :class="button.type"
          v-for="button in buttons"
          :key="button.name"
          @click="button.action"
        >
          {{ button.name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CloseIcon from "@/assets/icons/close.svg"
export default {
  name: "Modal",
  components: {
    CloseIcon
  },
  props: {
    isOxilioModal: {
      default: false
    },
    className: {
      default: {}
    },
    buttons: Array,
    noPadding: Boolean
  },
  methods: {
    confirm() {
      this.$emit("confirm")
    },
    close() {
      this.$emit("close")
    }
  }
}
</script>
<style scoped lang="scss">
.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(grey, 0.5);
  &.noPadding .popin {
    padding: 0;
  }
  .special-class {
    max-width: 350px !important;
  }
  .popin {
    position: relative;
    background-color: white;
    border-radius: 20px;
    min-height: 384px;
    min-width: 384px;
    max-height: 80vh;
    max-width: 80vw;
    overflow: auto;
    padding-top: 32px;
    padding-bottom: 64px;
    .close {
      position: absolute;
      top: 32px;
      right: 32px;
      width: 26px;
      cursor: pointer;
      z-index: 10;
    }
    .buttons {
      position: absolute;
      display: flex;
      bottom: 0;
      height: 64px;
      width: 100%;
      background-color: lighten($grey-color, 60);
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
          background-color: lighten($grey-color, 50);
          font-weight: bold;
          &.main {
            background-color: darken($red-color, 15);
          }
        }
        &.main {
          background-color: $red-color;
          color: white;
        }
      }
    }
  }
  .oxilio-modal {
    min-width: 800px;
    min-height: 600px;
    padding-bottom: 30px;
  }
}
.size-modal {
  min-width: 1200px !important;
  min-height: 720px;
  padding-bottom: 35px !important;
}
.size-modal-patient {
  min-width: 1200px !important;
  min-height: 720px !important;
  padding-bottom: 35px !important;
}
</style>
