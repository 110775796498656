<template>
  <Modal
    class="exercise-preview-modal"
    @close="close"
    :specialModal="true"
    :buttons="[
      { name: ButtonConfirmationTxt, type: 'main', action: confirm },
      { name: 'Annuler', action: close }
    ]"
  >
    <span v-if="headerTxt" class="header-txt">{{ headerTxt }}</span>
    <span class="span-modal">{{ modalMessage }} </span>
  </Modal>
</template>

<script>
import Modal from "@/components/ConfirmationModal/Modal"

export default {
  name: "ModalConfirmation",
  components: {
    Modal
  },
  props: {
    modalMessage: {
      default: "Etes-vous sûr de vouloir supprimer cet élément ?"
    },
    ButtonConfirmationTxt: {
      default: "Supprimer"
    },
    headerTxt: {
      default: ""
    }
  },
  computed: {},
  methods: {
    close() {
      this.$emit("close")
    },
    confirm() {
      this.$emit("confirm")
    }
  }
}
</script>

<style lang="scss" scoped>
.span-modal {
  margin-right: 60px;
  margin-left: 60px;
  margin-top: 100px;
  text-align: center;
  display: flex;
  font-size: 20px;
}
.header-txt {
  font-size: 19px;
  justify-content: center;
  text-align: center;
  display: flex;
  font-weight: bold;
  margin: 0px 65px;
}
</style>
