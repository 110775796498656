<template>
  <div class="field">
    <label :for="name">{{ title }}</label>

    <div
      class="select-wrapper"
      :class="{ opened: isOpened }"
      v-clickout="() => (isOpened = false)"
    >
      <button class="select-btn" @click="isOpened = !isOpened">
        {{ formatSelectedOptions }}
        <span class="icon">
          <SVGArrow />
        </span>
      </button>

      <div v-if="isOpened" class="select-dropdown">
        <div
          v-for="(option, i) in options"
          :key="i"
          class="item"
          :class="{ isSelected: selectedOptions.includes(option.value) }"
          @click="toggleOption(option.value)"
        >
          <div v-if="selectedOptions.includes(option.value)" class="check-icon">
            <SVGCheck />
          </div>
          <p>{{ option.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SVGArrow from "@/assets/icons/arrow.svg"
import SVGCheck from "@/assets/icons/check.svg"

export default {
  name: "MultipleSelect",
  components: {
    SVGArrow,
    SVGCheck
  },
  props: {
    title: String,
    name: String,
    value: {
      type: Array
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isOpened: false,
      selectedOptions: []
    }
  },
  created() {
    if (this.value) {
      this.selectedOptions = this.value
    }
  },
  methods: {
    toggleOption(option) {
      if (!this.selectedOptions.includes(option)) {
        this.selectedOptions.push(option)
      } else {
        const index = this.selectedOptions.indexOf(option)
        if (index > -1) {
          this.selectedOptions.splice(index, 1)
        }
      }
      this.$emit("input", this.selectedOptions)
    }
  },
  computed: {
    formatSelectedOptions() {
      if (this.selectedOptions.length > 1)
        return this.selectedOptions.length + " éléments sélectionnés"
      else if (this.selectedOptions.length > 0)
        return this.selectedOptions.length + " élément sélectionné"
      else return "Sélectionner"
    }
  },
  watch: {
    isOpened(opened) {
      if (!opened) this.$emit("blur")
    },
    value(oldValue) {
      this.selectedOptions = oldValue
    }
  }
}
</script>

<style lang="scss" scoped>
.field {
  display: flex;
  flex-direction: column;
  padding: 15px 10px;

  label {
    font-size: 1.2rem;
    font-weight: 420;
    margin-bottom: 10px;
  }

  .select-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;

    .select-btn {
      position: relative;
      padding: 10px;
      text-align: left;
      font-size: 1.05rem;
      background: $light-background-color;
      -webkit-appearance: none;
      border: 1px solid $light-background-color;
      border-radius: 10px;
      line-height: 1.5rem;
      transition: border 0.25s;

      &:focus {
        outline: none;
        border: 1px solid $light-text-color;
      }

      &:hover {
        border: 1px solid $light-text-color;
      }

      .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.25s;
        right: 10px;
        width: 14px;
      }
    }

    .select-dropdown {
      position: absolute;
      z-index: 5;
      top: 100%;
      width: 100%;
      overflow: hidden;
      background: $light-background-color;
      border: 1px solid $light-text-color;
      border-top: none;
      border-radius: 0px 0px 10px 10px;

      .item {
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        transition: all 0.25s;
        line-height: 1.2rem;

        &:hover {
          background: $white-color;
        }

        &.isSelected {
          background: $white-color;
          color: $main-color;
        }

        .check-icon {
          width: 15px;
          margin-right: 10px;
        }
      }
    }

    &.opened {
      .select-btn {
        border-bottom: none;
        border-radius: 10px 10px 0px 0px;
        border: 1px solid $light-text-color;
        border-bottom: none;

        .icon {
          transform: translateY(-50%) rotateX(180deg);
        }
      }
    }
  }
}
</style>
