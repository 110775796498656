<template>
  <div class="field">
    <label :for="name">{{ title }}</label>

    <div class="range">
      <input
        type="range"
        :id="name"
        :value="valueChanged"
        @input="handleInputChange"
        :min="min"
        :max="max"
        :step="step"
      />
      <p class="value">{{ valueChanged + "" + unit }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Range",
  data() {
    return {
      valueChanged: 0
    }
  },
  props: {
    title: String,
    name: String,
    value: {
      type: Number,
      default: 0
    },
    initialValue: {
      type: Number
    },
    step: [Number, String],
    min: Number,
    max: Number,
    unit: {
      type: String,
      default: ""
    }
  },
  created() {
    if (this.initialValue) {
      this.valueChanged = this.initialValue
    }
  },
  methods: {
    handleInputChange($event) {
      this.$emit("input", parseInt($event.target.value))
      this.valueChanged = $event.target.value
    }
  }
}
</script>

<style lang="scss" scoped>
.field {
  display: flex;
  flex-direction: column;
  padding: 15px 10px;

  label {
    font-size: 1.2rem;
    font-weight: 420;
    margin-bottom: 10px;
  }

  .range {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    input {
      flex: 1;
      padding: 10px 10px;
      font-size: 1rem;
      margin-right: 20px;

      &:focus {
        outline: none;
      }
    }

    .value {
      min-width: 50px;
      font-size: 1.2rem;
      text-align: right;
    }
  }
}
</style>
